import { keepPreviousData } from '@tanstack/react-query'
import { useMemo } from 'react'

import { useAppInstancesApi } from 'api/apps/queries/useAppInstancesApi'
import { useGenerateDevHubDownloadUrlsApi } from 'api/apps/queries/useGenerateDevHubDownloadUrlsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AppInstancesDefaults } from 'constants/appInstances'
import { PageSize } from 'constants/pageSize'
import { queryClient } from 'providers/osQueryClient/utils'
import { AppInstanceAssignmentType, AppInstanceShort } from 'types/apps/appInstances'

export const useHierarchyNodeAppInstances = (options: Parameters<typeof useAppInstancesApi>[0]) => {
  const params: NonNullable<typeof options>['params'] = {
    assignmentType: AppInstanceAssignmentType.Workspace,
    itemsPerPage: PageSize.All,
    ...options?.params,
  }

  return useAppInstancesApi({
    ...options,
    params,
    staleTime: options?.staleTime || AppInstancesDefaults.NavigationStaleTime,
  })
}

export const invalidateHierarchyNodeAppInstances = (assignmentId: string) =>
  queryClient.invalidateQueries({
    queryKey: [ApiQueryKeys.APP_INSTANCES, { assignmentId }],
    exact: false,
  })

export const useDevHubLogo = (key?: string) => {
  const { data: logoUrlData, isLoading } = useGenerateDevHubDownloadUrlsApi({
    params: { keys: [key!] },
    enabled: !!key,
    staleTime: AppInstancesDefaults.LogoStaleTime,
    placeholderData: keepPreviousData,
  })
  const [logoDownloadInfo] = logoUrlData
  return { logoUrl: logoDownloadInfo?.signedUrl, isLoading }
}

export const useGetDevHubLogoUrl = (appInstances: AppInstanceShort[]) => {
  const keys = useMemo(
    () =>
      appInstances
        .filter(({ devhubMetadata }) => !!devhubMetadata?.logoMeta?.thumbnail)
        .map(({ devhubMetadata }) => devhubMetadata.logoMeta!.thumbnail.key)
        .filter(key => key),
    [appInstances],
  )

  const { data } = useGenerateDevHubDownloadUrlsApi({
    params: {
      keys,
    },
    staleTime: 30000,
    placeholderData: keepPreviousData,
    enabled: keys.length > 0,
  })

  const logoUrlsMap = useMemo(() => Object.fromEntries(data.map(({ key, signedUrl }) => [key, signedUrl])), [data])

  return (key?: string) => (key && logoUrlsMap[key]) || ''
}

export const sanitizeOsRoute = (s: string, separator = '-') =>
  s
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    // Remove unallowed characters
    .replace(/[^a-z0-9-/ ]/g, '')
    // Trim whitespaces and '/' on both ends
    .replace(/^[\s/]+|[\s/]+$/g, '')
    // Replace whitespaces with dashes
    .replace(/\s+/g, separator)
