import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Fragment, ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/appsTreeList/AppsTreeList.module.scss'
import { useGroupAppInstances } from 'components/treeList/appsTreeList/utils'
import { LoadingTreeList } from 'components/treeList/loadingTreeList/LoadingTreeList'
import { AppInstanceShort } from 'types/apps/appInstances'

interface Props {
  isLoading?: boolean
  appInstances: AppInstanceShort[]
  renderApp: (appInstance: AppInstanceShort, i: number, source: AppInstanceShort[]) => JSX.Element
  emptyState?: ReactNode
  action?: ReactNode
}

export const AppsTreeList = ({
  isLoading = false,
  appInstances,
  renderApp,
  emptyState = null,
  action = null,
}: Props) => {
  const { ungrouped, grouped } = useGroupAppInstances({ appInstances })

  if (isLoading) {
    return <LoadingTreeList />
  }

  return (
    <Flex direction="column" gap={8} data-testid="apps-tree-list">
      {!!ungrouped.length && (
        <Flex direction="column" gap={2} data-testid="ungrouped-apps">
          {ungrouped.map((appInstance, i) => (
            <Fragment key={appInstance.id}>{renderApp(appInstance, i, ungrouped)}</Fragment>
          ))}
        </Flex>
      )}

      {grouped.map(({ group, appInstances, subgroups }) => (
        <Flex key={group.id} className={styles.group} gap={6} direction="column" data-testid="app-group">
          <Flex className={styles.groupHeader} direction="column">
            <WppTypography type="m-strong" data-testid="app-group-header">
              {group.name}
            </WppTypography>

            {!!group.description && (
              <WppTypography
                className={styles.groupDescription}
                type="xs-body"
                title={group.description}
                data-testid="app-group-subheader"
              >
                {group.description}
              </WppTypography>
            )}
          </Flex>

          {!!subgroups.length &&
            subgroups.map(({ subgroup, appInstances }) => (
              <Flex direction="column" key={subgroup.id} className={styles.subGroup} data-testid="app-sub-group">
                <Flex direction="column">
                  <WppTypography type="s-strong" data-testid="app-sub-group-header">
                    {subgroup.name}
                  </WppTypography>

                  {!!subgroup.description && (
                    <WppTypography type="xs-body" title={subgroup.description} data-testid="app-sub-group-subheader">
                      {subgroup.description}
                    </WppTypography>
                  )}
                </Flex>
                <Flex gap={2} direction="column">
                  {appInstances.map((appInstance, i) => (
                    <Flex data-testid="app-sub-group-item" key={appInstance.id}>
                      {renderApp(appInstance, i, appInstances)}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            ))}

          <Flex gap={2} direction="column">
            {appInstances.map((appInstance, i) => (
              <Fragment key={appInstance.id}>{renderApp(appInstance, i, appInstances)}</Fragment>
            ))}
          </Flex>
        </Flex>
      ))}

      {!appInstances.length && emptyState}
      {action}
    </Flex>
  )
}
